.p-index-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 52px;
  background-image: url(/assets/img/index/info-bg-sp.jpg);
  background-size: 100% 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  @media screen and (min-width: $min-width-xs) {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (min-width: $min-width-sm) {
    background-image: url(/assets/img/index/info-bg-pc.jpg);
    min-height: 50px;
  }
  p {
    width: calc(100% - 92px);
    max-width: fit-content;
    font-size: 13px;
    font-weight: $font-weight-bold;
    line-height: 17px;
    letter-spacing: 0.025em;
    color: #FFFFFF;
    text-shadow: 0 0 10px #003436;
    @media screen and (min-width: $min-width-md) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  .link {
    display: block;
    width: 82px;
    height: 21px;
    margin-left: 10px;
    font-size: 8px;
    font-weight: $font-weight-black;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    background-color: #2AA3AC;
    box-shadow: 0 3px 6px rgba(#003436, 0.5);
    border-radius: 11px;
    @media screen and (min-width: $min-width-md) {
      width: 124px;
      height: 29px;
      font-size: 13px;
      line-height: 29px;
      border-radius: 15px;
    }
  }

  + .p-index-top {
    margin-top: 0;
  }
}