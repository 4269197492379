@mixin hover () {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
  @media (hover: none) {
    &:active {
      @content;
    }
  }
}