.p-training-header {
  width: 100%;
  height: 415px;
  margin-bottom: 45px;
  @media screen and (min-width: $min-width-sm) {
    height: 486px;
    margin-bottom: 90px;
  }
  @media screen and (min-width: 1441px) {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 26px;
    padding-right: 26px;
    background-image: url(/assets/img/training/header-sp.jpg);
    background-size: cover;
    background-position: center center;
    @media screen and (min-width: $min-width-sm) {
      padding-left: 30px;
      padding-right: 30px;
      background-image: url(/assets/img/training/header-pc.jpg);
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 428px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    font-size: 18px;
    font-weight: $font-weight-bold;
    font-weight: bold;
    line-height: 36px;
    letter-spacing: 0.025em;
    text-align: center;
    color: #112B0B;
    @media screen and (min-width: $min-width-sm) {
      max-width: 757px;
      height: 286px;
      font-size: 40px;
      line-height: 75px;
    }
    strong {
      position: relative;
      font-weight: $font-weight-bold;
      &::after {
        position: absolute;
        bottom: 2px;
        left: -3px;
        display: block;
        content: "";
        mix-blend-mode: multiply;
        background-color: $color-yellow;
        width: calc(100% + 6px);
        height: 7px;
        @media screen and (min-width: $min-width-sm) {
          bottom: 2px;
          left: -2px;
          width: calc(100% + 4px);
          height: 20px;
        }
      }
    }
  }
}
  