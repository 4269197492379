.p-index-logo {
  padding: 20px 0 40px;
  @media screen and (min-width: $min-width-sm) {
    padding: 35px 0 70px;
  }
  .title {
    width: fit-content;
    padding-bottom: 3px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 27px;
    border-bottom: 1px solid #707070;
    font-size: 12px;
    font-weight: $font-weight-bold;
    line-height: 18px;
    color: #666666;
    @media screen and (min-width: $min-width-sm) {
      padding-bottom: 7px;
      padding-left: 13px;
      padding-right: 13px;
      margin-bottom: 55px;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .logo {
    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      text-align: center;
      object-fit: cover;
      transition: opacity 0.5s ease-out;
      @media screen and (min-width: $min-width-sm) {
        height: 60px;
      }
      img {
        width: auto;
        height: 40px;
        @media screen and (min-width: $min-width-sm) {
          height: 60px;
          margin-left: 15px;
          margin-right: 15px;
        }
        @media screen and (min-width: $min-width-md) {
          height: 3.90625vw;
        }
        @media screen and (min-width: $min-width-l) {
          height: 60px;
        }
      }
    }
    .swiper-wrapper > div {
      opacity: 0;
      transition: opacity 0.2s;
      &.swiper-slide-active,
      &.swiper-slide-duplicate-active {
        opacity: 1;
        transition: opacity 0.2s 0.5s;
      }
    }

    &.is-col1 {
      @media screen and (min-width: $min-width-md) {
        display: none;
      }
    }
    &.is-col3 {
      display: none;
      @media screen and (min-width: $min-width-md) {
        display: block;
      }
    }

    .logo-col1-prev,
    .logo-col3-prev {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      z-index: 2;
      @media screen and (min-width: $min-width-sm) {
        left: 90px;
      }
      @media screen and (min-width: $min-width-xl) {
        left: auto;
        right: calc(50% + 580px);
      }
    }

    .logo-col1-next,
    .logo-col3-next {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      z-index: 2;
      @media screen and (min-width: $min-width-sm) {
        right: 90px;
      }
      @media screen and (min-width: $min-width-xl) {
        right: auto;
        left: calc(50% + 580px);
      }
    }
  }
}
  