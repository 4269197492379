.p-training-impact-header {
  width: 100%;
  height: 415px;
  @media screen and (min-width: $min-width-sm) {
    height: 486px;
  }
  @media screen and (min-width: 1441px) {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 26px;
    padding-right: 26px;
    background-size: auto 100%;
    background-position: center center;
    border: 1px solid #666666;
    @media screen and (min-width: $min-width-sm) {
      padding-left: 30px;
      padding-right: 30px;
      background-size: auto 100%;
    }
    @media screen and (min-width: $min-width-md) {
      background-size: auto 630px;
    }
    &.is-design {
      background-image: url(/assets/img/training/impact/header-design-sp.png);
      @media screen and (min-width: $min-width-sm) {
        background-image: url(/assets/img/training/impact/header-design-pc.png);
      }
    }
    &.is-managiment {
      background-image: url(/assets/img/training/impact/header-managiment-sp.png);
      @media screen and (min-width: $min-width-sm) {
        background-image: url(/assets/img/training/impact/header-managiment-pc.png);
      }
    }

    @media screen and (min-width: $min-width-sm) {
      &.is-design .box {
        max-width: 807px;
      }
      &.is-managiment .box {
        max-width: 807px;
      }
    }
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 428px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
    background-color: #000;
    @media screen and (min-width: $min-width-sm) {
      height: 286px;
    }
  }

  .lead {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: $font-weight-bold;
    line-height: 18px;
    letter-spacing: 0.025em;
    text-align: center;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 20px;
      font-size: 26px;
      line-height: 43px;
    }
    @media screen and (min-width: $min-width-md) {
      font-size: 29px;
    }
  }

  .tagline {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: $font-weight-bold;
    line-height: 18px;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 25px;
      font-size: 21px;
      line-height: 40px;
    }
    @media screen and (min-width: $min-width-md) {
      font-size: 27px;
    }
    &::before {
      display: block;
      content: "";
      width: 48px;
      height: 0;
      border-bottom: 1px solid #fff;
      margin-right: 10px;
      @media screen and (min-width: $min-width-sm) {
        width: 173px;
        margin-right: 7px;
      }
    }
    &::after {
      display: block;
      content: "";
      width: 48px;
      height: 0;
      border-bottom: 1px solid #fff;
      margin-left: 10px;
      @media screen and (min-width: $min-width-sm) {
        width: 173px;
        margin-left: 7px;
      }
    }
  }

  .title {
    font-size: 21px;
    font-weight: $font-weight-bold;
    line-height: 31px;
    text-align: center;
    color: #fff;
    @media screen and (min-width: $min-width-sm) {
      font-size: 48px;
      line-height: 80px;
    }
    @media screen and (min-width: $min-width-md) {
      font-size: 54px;
    }
  }

  &.is-design .title strong {
    color: $color-yellow;
  }
  &.is-managiment .title strong {
    color: #FF7258;
  }
}
  