.l-footer-section {
  padding-top: 4px;
  padding-bottom: 4px;
  @media screen and (min-width: $min-width-xs) {
    padding: 8px 16px;
  }
  @media screen and (min-width: $min-width-l) {
    padding: 12px 48px;
  }
  @media screen and (min-width: $min-width-xl) {
    padding-left: 80px;
    padding-right: 80px;
  }
  .container {
    background-color: #555;
    padding: 40px 24px;
    @media screen and (min-width: $min-width-xs) {
      padding: 80px 24px;
      border-radius: 8px;
    }
    @media screen and (min-width: $min-width-l) {
      padding: 96px 48px;
    }
    @media screen and (min-width: $min-width-xl) {
      padding-left: 80px;
      padding-right: 80px;
    }
  }

  .logo {
    width: 192px;
    img {
      display: block;
      width: auto;
      height: 100%;
    }
  }

  ul {
    li {
      a {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
      }
      + li {
        margin-top: 8px;
      }
    }
  }
}