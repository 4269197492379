.c-pagetop {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: block;
  width: 50px;
  height: 50px;
  background-image: url(/assets/img/common/arrow-pc.svg);
  background-size: 100%;
  transform-origin: center;
  transform: rotate(-90deg);
  z-index: 2;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
  &.is-show {
    opacity: 0.8;
    @include hover {
      opacity: 1;
    }
  }
  @media screen and (max-width: $max-width-sm) {
    display: none;
  }
}