.p-contact-thanks {
  padding-top: 45px;
  padding-bottom: 45px;
  @media screen and (min-width: $min-width-sm) {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .title {
    margin-bottom: 45px;
    font-size: 18px;
    line-height: 27px;
    font-weight: $font-weight-bold;
    letter-spacing: 0.025em;
    text-align: center;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 90px;
      font-size: 40px;
      line-height: 59px;
    }
  }

  .thanks {
    max-width: 518px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 45px;
    padding-left: 45px;
    padding-right: 45px;
    @media screen and (min-width: $min-width-sm) {
      max-width: 816px;
      margin-bottom: 90px;
    }
    .text {
      font-size: 12px;
      line-height: 25px;
      letter-spacing: 0.025em;
      @media screen and (min-width: $min-width-sm) {
        font-size: 18px;
        line-height: 45px;
      }
      + .text {
        margin-top: 25px;
        @media screen and (min-width: $min-width-sm) {
          line-height: 45px;
        }
      }
    }
  }
}