.c-prev {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url(/assets/img/common/arrow-sp.svg);
  background-size: 100%;
  transform-origin: center;
  transform: rotate(180deg);
  cursor: pointer;
  @media screen and (min-width: $min-width-sm) {
    width: 50px;
    height: 50px;
    background-image: url(/assets/img/common/arrow-pc.svg);
  }
  transition: opacity 0.2s;
  &.swiper-button-disabled {
    pointer-events: none;
    opacity: 0;
  }
}