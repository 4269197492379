.c-contact {
  margin-bottom: 45px;
  @media screen and (min-width: $min-width-sm) {
    margin-top: 50px;
    margin-bottom: 75px;
    width: 100%;
  }

  .title {
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: $font-weight-bold;
    line-height: 29px;
    text-align: center;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 4px;
      font-size: 40px;
      line-height: 60px;
    }
  }
  .subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 23px;
    font-size: 12px;
    font-weight: $font-weight-medium;
    line-height: 17px;
    color: #666666;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 65px;
      font-size: 14px;
      line-height: 20px;
    }
    &::before {
      display: block;
      content: "";
      width: 45px;
      height: 0;
      border-bottom: 1px solid #666666;
      margin-right: 9px;
      @media screen and (min-width: $min-width-sm) {
        width: 98px;
      }
    }
    &::after {
      display: block;
      content: "";
      width: 45px;
      height: 0;
      border-bottom: 1px solid #666666;
      margin-left: 9px;
      @media screen and (min-width: $min-width-sm) {
        width: 98px;
      }
    }
  }
  
  .text {
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 27px;
    font-size: 12px;
    line-height: 25px;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 57px;
      font-size: 18px;
      line-height: 45px;
    }
  }
}
  