.p-about-company {
  padding-top: 112px;
  margin-top: -77px;
  padding-bottom: 75px;
  @media screen and (min-width: $min-width-sm) {
    padding-top: 127px;
    margin-top: -77px;
    padding-bottom: 75px;
  }

  .title {
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: $font-weight-bold;
    line-height: 29px;
    text-align: center;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 4px;
      font-size: 40px;
      line-height: 60px;
    }
  }
  .subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 36px;
    font-size: 12px;
    line-height: 17px;
    color: #666666;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 65px;
      font-size: 14px;
      line-height: 20px;
    }
    &::before {
      display: block;
      content: "";
      width: 45px;
      height: 0;
      border-bottom: 1px solid #666666;
      margin-right: 9px;
      @media screen and (min-width: $min-width-sm) {
        width: 98px;
      }
    }
    &::after {
      display: block;
      content: "";
      width: 45px;
      height: 0;
      border-bottom: 1px solid #666666;
      margin-left: 9px;
      @media screen and (min-width: $min-width-sm) {
        width: 98px;
      }
    }
  }
  
  .company {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 35px;
    padding-right: 35px;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0.025em;
    @media screen and (min-width: $min-width-sm) {
      max-width: 704px;
      font-size: 18px;
      line-height: 34px;
    }
    dt {
      width: 87px;
      @media screen and (min-width: $min-width-sm) {
        width: 164px;
      }
      &:nth-of-type(n+2) {
        margin-top: 25px;
      }
    }
    dd {
      width: calc(100% - 87px);
      @media screen and (min-width: $min-width-sm) {
        width: calc(100% - 164px);
      }
      &:nth-of-type(n+2) {
        margin-top: 25px;
      }
    }
  }
}
  