.l-article {
  > .title {
    margin-bottom: 48px;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 0.05em;
    text-align: center;
    @media screen and (min-width: $min-width-l) {
      margin-bottom: 64px;
      font-size: 20px;
    }
    .is-en {
      font-family: $font-en;
      font-size: 36px;
      @media screen and (min-width: $min-width-l) {
        font-size: 48px;
      }
    }
  }
}