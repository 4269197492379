.p-tool-step {
  .p-tool-title {
    margin-bottom: 41px;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 60px;
    }
  }

  .label {
    &.is-sp {
      margin-bottom: 30px;
      font-size: 14px;
      font-weight: $font-weight-bold;
      line-height: 20px;
      color: #666666;
      text-align: center;
      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        margin-left: 5px;
        margin-right: 5px;
        color: #00B3BF;
        border: 1px solid #00B3BF;
        border-radius: 50%;
      }
      @media screen and (min-width: $min-width-sm) {
        display: none;
      }
    }
    
  }

  .step {
    margin-bottom: 60px;
    @media screen and (min-width: $min-width-sm) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 757px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 135px;
      
    }
    dt {
      &:not(.is-label) {
        padding: 13px 24px 10px;
        font-size: 14px;
        line-height: 20px;
        background-color: #E5E2E2;
        @media screen and (min-width: $min-width-sm) {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 30px 0 29px;
          font-size: 21px;
          line-height: 31px;
          &:not(:last-of-type) {
            margin-bottom: 46px;
            &::after {
              position: absolute;
              bottom: -32px;
              left: 50%;
              content: "▼";
              font-size: 21px;
              line-height: 1;
              color: #00B3BF;
              transform: translateX(-50%);
            }
          }
        }
      }
      &.is-label {
        margin-bottom: 25px;
        font-size: 21px;
        text-align: center;
        color: #666666;
        @media screen and (max-width: $max-width-sm) {
          display: none;
        }
      }
      @media screen and (min-width: $min-width-sm) {
        width: 37%;
      }
    }
    dd {
      &:not(.is-label) {
        position: relative;
        border: 1px solid #E5E2E2;
        padding: 11px 24px 11px 47px;
        font-size: 14px;
        line-height: 28px;
        @media screen and (min-width: $min-width-sm) {
          padding: 25px 0 20px;
          font-size: 21px;
          line-height: 42px;
          text-align: center;
          border: 5px solid #E5E2E2;
          &:not(:last-of-type) {
            margin-bottom: 46px;
          }
        }
        @media screen and (max-width: $max-width-sm) {
          &::before {
            position: absolute;
            top: 16px;
            left: 24px;
            content: "?";
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            margin-right: 5px;
            color: #00B3BF;
            border: 1px solid #00B3BF;
            border-radius: 50%;
          }
          &:not(:last-child) {
            margin-bottom: 50px;
            &::after {
              position: absolute;
              bottom: -32px;
              left: 50%;
              content: "▼";
              font-size: 13px;
              line-height: 1;
              color: #00B3BF;
              transform: translateX(-50%);
            }
          }
        }
      }
      &.is-label {
        font-size: 21px;
        font-weight: $font-weight-bold;
        line-height: 31px;
        text-align: center;
        color: #666666;
        @media screen and (max-width: $max-width-sm) {
          display: none;
        }
        span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          margin-right: 12px;
          color: #00B3BF;
          border: 3px solid #00B3BF;
          border-radius: 50%;
        }
      }
      @media screen and (min-width: $min-width-sm) {
        width: 63%;
      }
    }
  }
}