.p-index-movie {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 60px 15px;
  background-color: rgba(#000, 0.6);
  z-index: 2;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s,visibility 0.2s;
  &.is-show {
    pointer-events: auto;
    visibility: visible;
    opacity: 1;
  }
  @media screen and (min-width: $min-width-sm) {
    padding: 90px 30px;
  }

  .movie {
    position:relative;
    width: 100%;
    max-width: 428px;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    @media screen and (min-width: $min-width-sm) {
      max-width: 1129px;
      margin-top: 90px;
    }
  }

  .close {
    position: absolute;
    top: -44px;
    right: 0;
    font-size: 30px;
    line-height: 44px;
    z-index: 2;
    cursor: pointer;
    @media screen and (min-width: 550px) {
      right: -44px;
    }
    @media screen and (min-width: $min-width-sm) {
      top: -73px;
      right: 0;
      font-size: 50px;
      line-height: 73px;
    }
    @media screen and (min-width: 1340px) {
      right: -73px;
    }
  }
  
}
  