.p-about-leadership {
  padding-top: 35px;
  padding-bottom: 55px;
  @media screen and (min-width: $min-width-sm) {
    padding-top: 50px;
    padding-bottom: 75px;
  }
  .title {
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: $font-weight-bold;
    line-height: 29px;
    text-align: center;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 4px;
      font-size: 40px;
      line-height: 60px;
    }
  }
  .subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    font-size: 12px;
    line-height: 17px;
    color: #666666;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 65px;
      font-size: 14px;
      line-height: 20px;
    }
    &::before {
      display: block;
      content: "";
      width: 45px;
      height: 0;
      border-bottom: 1px solid #666666;
      margin-right: 9px;
      @media screen and (min-width: $min-width-sm) {
        width: 98px;
      }
    }
    &::after {
      display: block;
      content: "";
      width: 45px;
      height: 0;
      border-bottom: 1px solid #666666;
      margin-left: 9px;
      @media screen and (min-width: $min-width-sm) {
        width: 98px;
      }
    }
  }
  
  .leadership {
    display: flex;
    flex-wrap: wrap;
    width: 362px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: $min-width-sm) {
      width: 482px;
    }
    @media screen and (min-width: $min-width-l) {
      width: 1004px;
      justify-content: center;
      
    }
    .leader {
      width: 181px;
      @media screen and (min-width: $min-width-sm) {
        width: 241px;
      }
      @media screen and (max-width: $max-width-l) {
        &:nth-child(n+3) {
          margin-top: 53px;
          @media screen and (min-width: $min-width-sm) {
            margin-top: 65px;
          }
        }
      }
      + .leader {
        @media screen and (min-width: $min-width-l) and (max-width: $max-width-l) {
          &:nth-child(2n) {
            margin-left: 40px;
          }
        }
        @media screen and (min-width: $min-width-l) {
          margin-left: 40px;
        }
      }
      a {
        cursor: pointer;
        .img {
          width: 140px;
          height: 140px;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 12px;
          border-radius: 50%;
          overflow: hidden;
          border: 1px solid #C2C2C2;
          @media screen and (min-width: $min-width-sm) {
            width: 170px;
            height: 170px;
            margin-bottom: 15px;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
        .name {
          font-size: 14px;
          line-height: 20px;
          font-weight: bold;
          text-align: center;
          @media screen and (min-width: $min-width-sm) {
            font-size: 20px;
            line-height: 31px;
          }
        }
        .alphabet {
          font-size: 10px;
          line-height: 16px;
          color: #666666;
          text-align: center;
          @media screen and (min-width: $min-width-sm) {
            margin-bottom: 9px;
            font-size: 14px;
            line-height: 16px;
          }
        }
        .posistion {
          margin-bottom: 7px;
          font-size: 10px;
          line-height: 16px;
          text-align: center;
          @media screen and (min-width: $min-width-sm) {
            margin-bottom: 21px;
            font-size: 18px;
            line-height: 28px;
          }
        }
      }

      .sns {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
          a {
            display: flex;
            align-items: center;
          }
          + li {
            margin-left: 7px;
            @media screen and (min-width: $min-width-sm) {
              margin-left: 15px;
            }
          }
        }
        .facebook {
          a {
            width: 15px;
            @media screen and (min-width: $min-width-sm) {
              width: 30px;
            }
            img {
              width: 100%;
              height: auto;
            }
          }
        }
        .twitter {
          a {
            width: 15px;
            @media screen and (min-width: $min-width-sm) {
              width: 30px;
            }
            img {
              width: 100%;
              height: auto;
            }
          }
        }
        .instagram {
          a {
            width: 15px;
            @media screen and (min-width: $min-width-sm) {
              width: 30px;
            }
            img {
              width: 100%;
              height: auto;
            }
          }
        }
        .note {
          a {
            height: 15px;
            @media screen and (min-width: $min-width-sm) {
              height: 30px;
            }
            img {
              width: auto;
              height: 100%;
            }
          }
        }
      }
      
    }
  }

  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    @media screen and (max-width: $max-width-xs) {
      right: 20px;
    }
    @media screen and (min-width: $min-width-xs) {
      left: calc(50% + 166px);
    }
    @media screen and (min-width: $min-width-sm) {
      left: calc(50% + 304px);
    }
    @media screen and (min-width: $min-width-l) {
      left: calc(50% + 580px);
    }
    transition: opacity 0.2s;
    &.swiper-button-disabled {
      pointer-events: none;
      opacity: 0;
    }
  }
  .prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    @media screen and (max-width: $max-width-xs) {
      left: 20px;
    }
    @media screen and (min-width: $min-width-xs) {
      right: calc(50% + 166px);
    }
    @media screen and (min-width: $min-width-sm) {
      right: calc(50% + 304px);
    }
    @media screen and (min-width: $min-width-l) {
      right: calc(50% + 580px);
    }
    transition: opacity 0.2s;
    &.swiper-button-disabled {
      pointer-events: none;
      opacity: 0;
    }
  }

  .details {
    position: fixed;
    top: 77px;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    box-sizing: border-box;
    width: 100%;
    height: calc(100% - 77px);
    background-color: #fff;
    z-index: $z-leader-detail;
    transition: opacity 0.2s, visibility 0.2s;
    overflow-y: scroll;
    overflow-x: hidden;
    &.is-show {
      visibility: visible;
      pointer-events: auto;
      opacity: 1;
    }

    .wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      @media screen and (max-width: $max-width-sm) {
        padding-top: 45px;
      }
      @media screen and (max-width: $max-width-md) {
        max-width: 476px;
        margin-left: auto;
        margin-right: auto;
      }
      @media screen and (min-width: $min-width-md) {
        max-width: 1300px;
        padding-left: 100px;
        padding-right: 100px;
      }

      .prevnext {
        @media screen and (max-width: $max-width-sm) {
          display: none;
        }
        @media screen and (min-width: $min-width-sm) {
          position: fixed;
          top: calc(50% + 37px);
          left: 0;
          width: 100%;
        }
        @media screen and (min-width: 1300px) {
          width: 1100px;
          left: calc(50% - 550px);
        }

      }

      
      .detail-next,
      .detail-prev {
        transition: opacity 0.2s;
        opacity: 0.6;
        @include hover {
          opacity: 1;
        }
      }
      
      .detail-prev {
        width: 8px;
        height: 10px;
        cursor: pointer;
        @media screen and (max-width: $max-width-sm) {
          margin-right: 26px;
        }
        @media screen and (min-width: $min-width-sm) {
          position: absolute;
          top: 0;
          left: 110px;
          width: 16px;
          height: 19px;
          @media screen and (min-width: $min-width-md) {
            position: absolute;
            left: 42px;
          }
          @media screen and (min-width: 1300px) {
            left: -56px;
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .detail-next {
        width: 8px;
        height: 10px;
        cursor: pointer;
        @media screen and (max-width: $max-width-sm) {
          margin-left: 26px;
        }
        @media screen and (min-width: $min-width-sm) {
          position: absolute;
          top: 0;
          right: 110px;
          width: 16px;
          height: 19px;
          @media screen and (min-width: $min-width-md) {
            position: absolute;
            right: 42px;
          }
          @media screen and (min-width: 1300px) {
            right: -56px;
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
      }

      .header {
        position: fixed;
        top: 77px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 45px;
        background-color: #fff;
        z-index: $z-leader-detail;
        @media screen and (max-width: $max-width-sm) {
          padding-left: 18px;
          padding-right: 10px;
        }
        @media screen and (max-width: $max-width-md) {
          padding-left: 30px;
        }
        @media screen and (min-width: $min-width-sm) {
          position: relative;
          top: 0;
          height: 60px;
        }
  
        .details-title {
          font-size: 10px;
          line-height: 15px;
          font-weight: $font-weight-medium;
          @media screen and (min-width: $min-width-sm) {
            font-size: 14px;
            line-height: 20px;
          }
        }
        .close {
          font-size: 30px;
          line-height: 45px;
          color: #C2C2C2;
          cursor: pointer;
          transition: color 0.2s;
          @include hover {
            color: #666;
          }
          @media screen and (min-width: $min-width-sm) {
            position: fixed;
            top: 90px;
            right: 28px;
            font-size: 50px;
            line-height: 73px;
          }
        }
      }
  
      .detail {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding-bottom: 77px;
        display: none;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        &.is-show {
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
          display: block;
          opacity: 1;
          animation: fadeIn 0.2s ease-in 0s forwards;
          @media screen and (min-width: $min-width-md) {
            display: flex;
          }
        }
        @keyframes fadeIn {
          0% {
            display: none;
            opacity: 0;
          }
        
          1% {
            display: block;
            opacity: 0;
          }
        
          100% {
            display: block;
            opacity: 1;
          }
        }

        @media screen and (max-width: $max-width-sm) {
          top: 45px;
          padding-bottom: 45px;
        }
        @media screen and (max-width: $max-width-md) {
          padding-left: 30px;
          padding-right: 30px;
        }
        @media screen and (min-width: $min-width-sm) {
          top: 60px;
        }
        @media screen and (min-width: $min-width-md) {
          left: 100px;
          width: calc(100% - 200px);
          max-width: 1100px;
        }
        .img {
          width: 100%;
          margin-bottom: 20px;
          img {
            width: 100%;
            height: auto;
          }
        }
  
        .block1 {
          width: 100%;
          max-width: 416px;
          @media screen and (max-width: $max-width-md) {
            margin-left: auto;
            margin-right: auto;
          }
          .container {
            margin-bottom: 30px;
            @media screen and (max-width: $max-width-sm) {
              display: flex;
              align-items: center;
            }

            .detail-prev,
            .detail-next {
              @media screen and (min-width: $min-width-sm) {
                display: none;
              }
            }
    
            .box {
              flex: 1;
              padding: 10px 33px;
              @media screen and (min-width: $min-width-sm) {
                padding-top: 20px;
                padding-bottom: 20px;
              }
              @media screen and (max-width: $max-width-md) {
                background-color: #F4F3EF;
              }
              @media screen and (min-width: $min-width-md) {
                display: flex;
                flex-direction: column;
                padding: 0;
              }
    
              .posistion {
                margin-bottom: 10px;
                font-size: 14px;
                line-height: 20px;
                @media screen and (min-width: $min-width-sm) {
                  font-size: 18px;
                  line-height: 32px;
                }
                @media screen and (min-width: $min-width-md) {
                  margin-bottom: 17px;
                  order: 2;
                  text-align: center;
                }
              }
    
              .name {
                margin-bottom: 10px;
                font-size: 14px;
                line-height: 20px;
                font-weight: $font-weight-bold;
                @media screen and (min-width: $min-width-sm) {
                  margin-bottom: 17px;
                  font-size: 20px;
                  line-height: 32px;
                }
                @media screen and (min-width: $min-width-md) {
                  margin-bottom: 10px;
                  order: 1;
                  text-align: center;
                }
                small {
                  margin-left: 12px;
                  font-size: 10px;
                  font-weight: $font-weight-medium;
                  line-height: 20px;
                  @media screen and (min-width: $min-width-sm) {
                    font-size: 14px;
                    line-height: 22px;
                  }
                  @media screen and (min-width: $min-width-md) {
                    display: block;
                    color: #666;
                  }
                }
              }
    
              .sns {
                display: flex;
                align-items: center;
                @media screen and (min-width: $min-width-md) {
                  order: 3;
                  justify-content: center;
                }
                li {
                  width: 15px;
                  @media screen and (min-width: $min-width-sm) {
                    width: 30px;
                  }
                  a {
                    display: block;
                    img {
                      width: 100%;
                      height: auto;
                    }
                  }
                  + li {
                    margin-left: 7px;
                    @media screen and (min-width: $min-width-sm) {
                      margin-left: 14px;
                    }
                  }
                }
              }
            }
    
      
          }
        }
  
        
  
        .block2 {
          width: 100%;
          @media screen and (min-width: $min-width-md) {
            margin-left: 50px;
          }
          .text {
            font-size: 12px;
            line-height: 24px;
            @media screen and (min-width: $min-width-sm) {
              font-size: 16px;
              line-height: 36px;
            }
          }
        }
        
      }
    }
    
  }
    
}
  