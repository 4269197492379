.p-tool-title {
  margin-bottom: 30px;
  padding-top: 22px;
  padding-bottom: 22px;
  background-color: #00B3BF;
  color: #fff;
  font-size: 14px;
  font-weight: $font-weight-bold;
  line-height: 26px;
  text-align: center;
  @media screen and (min-width: $min-width-sm) {
    margin-bottom: 60px;
    max-width: 757px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 55px;
    padding-bottom: 55px;
    font-size: 30px;
  }
  img {
    width: 139px;
    height: auto;
    vertical-align: -5px;
    @media screen and (min-width: $min-width-sm) {
      width: 323px;
      margin-right: 11px;
      vertical-align: -10px;
    }
  }
}