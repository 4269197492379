.p-contact-form {
  max-width: 518px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 45px;
  padding-right: 45px;
  @media screen and (min-width: $min-width-sm) {
    max-width: 964px;
  }
  &:not(.is-type) {
    margin-bottom: 45px;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 90px;
    }
  }

  .block {
    @media screen and (min-width: $min-width-sm) {
      display: flex;
      .box {
        flex: 1;
      }
    }
    + .block {
      margin-top: 30px;
    }
  }
  
  .label {
    display: block;
    height: 18px;
    margin-bottom: 14px;
    font-size: 12px;
    line-height: 18px;
    @media screen and (max-width: $max-width-sm) {
      height: 18px;
    }
    @media screen and (min-width: $min-width-sm) {
      width: 207px;
      font-size: 18px;
      line-height: 27px;
      &:not(.is-type) {
        padding-top: 31px;
      }
    }
    .is-required {
      &::after {
        content: "＊";
        font-weight: $font-weight-bold;
        margin-left: 4px;
        color: $color-link;
      }
    }
    .is-error {
      font-size: 10px;
      font-weight: $font-weight-bold;
      font-weight: bold;
      color: #00B3BF;
      vertical-align: 1px;
      @media screen and (max-width: $max-width-sm) {
        margin-left: 6px;
      }
      @media screen and (min-width: $min-width-sm) {
        display: block;
        margin-top: 18px;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .type {
    display: flex;
    .radio {
      @media screen and (min-width: $min-width-sm) {
        display: flex;
        align-items: center;
      }
      + .radio {
        margin-left: 26px;
      }
      input[type="radio"] {
        display: none;
        &:checked + label {
          &::before {
            background-color: #666666;
          }
          &::after {
            position: absolute;
            top: 3px;
            left: 4px;
            display: block;
            width: 11px;
            height: 10px;
            content: "";
            background-image: url(/assets/img/contact/check-sp.svg);
            background-repeat: no-repeat;
            @media screen and (min-width: $min-width-sm) {
              top: 6px;
              left: 4px;
              width: 15px;
              height: 13px;
              background-image: url(/assets/img/contact/check-pc.svg);
            }
          }
        }
      }
      label {
        position: relative;
        white-space: nowrap;
        padding-left: 22px;
        font-size: 12px;
        line-height: 18px;
        @media screen and (min-width: $min-width-sm) {
          padding-left: 27px;
          font-size: 18px;
          line-height: 27px;
        }
        &::before {
          position: absolute;
          top: 2px;
          left: 0;
          display: block;
          width: 14px;
          height: 14px;
          border: 1px solid #000000;
          content: "";
          border-radius: 50%;
          @media screen and (min-width: $min-width-sm) {
            top: 6px;
            width: 18px;
            height: 18px;
          }
        }
      }

      .comment {
        margin-left: 22px;
        font-size: 10px;
        line-height: 15px;
        color: #666666;
        @media screen and (max-width: $max-width-sm) {
          margin-top: 9px;
        }
        @media screen and (min-width: $min-width-sm) {
          margin-left: 18px;
          font-size: 12px;
        }
      }
    }

    + .note {
      margin-top: 16px;
      margin-bottom: 25px;
      font-size: 10px;
      line-height: 20px;
      font-weight: $font-weight-bold;
      @media screen and (min-width: $min-width-sm) {
        margin-top: 12px;
        margin-bottom: 60px;
        font-size: 12px;
        line-height: 24px;
      }
      a {
        color: $color-link;
        text-decoration: underline;
      }
    }
  }

  .checkbox li {
    @media screen and (min-width: $min-width-sm) {
      display: flex;
    }
    + li {
      margin-top: 16px;
      @media screen and (min-width: $min-width-sm) {
        margin-top: 30px;
      }
    }
    input[type="checkbox"] {
      display: none;
      &:checked + label {
        &::before {
          background-color: #666666;
        }
        &::after {
          position: absolute;
          top: 3px;
          left: 4px;
          display: block;
          width: 11px;
          height: 10px;
          content: "";
          background-image: url(/assets/img/contact/check-sp.svg);
          background-repeat: no-repeat;
          @media screen and (min-width: $min-width-sm) {
            top: 6px;
            left: 4px;
            width: 15px;
            height: 13px;
            background-image: url(/assets/img/contact/check-pc.svg);
          }
        }
      }
    }
    label {
      position: relative;
      display: block;
      padding-left: 22px;
      font-size: 12px;
      font-weight: $font-weight-bold;
      line-height: 21px;
      @media screen and (min-width: $min-width-sm) {
        padding-left: 36px;
        font-size: 18px;
        line-height: 27px;
      }
      &::before {
        position: absolute;
        top: 3px;
        left: 0;
        display: block;
        width: 14px;
        height: 14px;
        border: 1px solid #000000;
        content: "";
        border-radius: 50%;
        @media screen and (min-width: $min-width-sm) {
          top: 6px;
          width: 18px;
          height: 18px;
        }
      }
    }

    .comment {
      margin-left: 22px;
      font-size: 12px;
      line-height: 21px;
      color: #666666;
      @media screen and (min-width: $min-width-sm) {
        margin-left: 18px;
        line-height: 27px;
      }
    }
  }

  

  input[type="text"] {
    width: 100%;
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid #707070;
    border-radius: 10px;
    font-size: 12px;
    line-height: 45px;
    letter-spacing: 0.025em;
    &::placeholder {
      color: #C2C2C2;
    }
    @media screen and (min-width: $min-width-sm) {
      flex:1;
      font-size: 18px;
      height: 90px;
      border-radius: 15px;
      line-height: 90px;
      padding-left: 38px;
      padding-right: 38px;
    }
    &.is-error {
      border-color: #00B3BF;
      background-color: #E5F7F9;
    }
  }

  textarea {
    width: 100%;
    height: 150px;
    padding: 20px;
    border: 1px solid #707070;
    border-radius: 10px;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.025em;
    @media screen and (min-width: $min-width-sm) {
      padding: 30px 38px;
      height: 437px;
      border-radius: 15px;
      font-size: 18px;
      line-height: 30px;
    }
    &.is-error {
      border-color: #00B3BF;
      background-color: #E5F7F9;
    }
    &::placeholder {
      color: #C2C2C2;
    }
    @media screen and (min-width: $min-width-sm) {
      flex:1;
    }
  }

  
  .select {
    position: relative;
    width: 100%;
    height: 42px;
    cursor: pointer;
    background-color: white;
    border-radius: 10px;
    @media screen and (min-width: $min-width-sm) {
      height: 86px;
      border-radius: 15px;
    }
    &-expand {
      position: relative;
      width: 0;
      height: 40px;
      position: absolute;
      top: 0;
      right: 0;
      appearance: none;
      @media screen and (min-width: $min-width-sm) {
        height: 84px;
      }
      
      &::after {
        display: block;
        content: '';
        position: absolute;
        top: 15px;
        right: 15px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 10px solid #000;
        pointer-events: none;
        z-index: 2;
        transition: opacity 0.2s;
        @media screen and (min-width: $min-width-sm) {
          top: 38px;
          right: 40px;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 14px solid #000;
        }
      }
      
      &:checked::after {opacity: 0;}
    }
    
    &-expand-label {
      display: block;
      width: 100%;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      @media screen and (min-width: $min-width-sm) {
        height: 84px;
      }
    }
    
    &-close {display: none}
    
    &-close-label {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      display: none;
    }
    
    &-items {
      width: 100%;
      min-height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid #707070;
      border-radius: 2px;
      padding-top: 30px;
      border-radius: 10px;
      @media screen and (min-width: $min-width-sm) {
        min-height: 84px;
        padding-top: 58px;
      }
    }
    
    &-input {display: none}
    
    &-label {
      position: relative;
      transition: all 250ms cubic-bezier(.4,.25,.3,1);
      display: block;
      height: 0;
      font-size: 12px;
      line-height: 30px;
      letter-spacing: 0.025em;
      overflow: hidden;
      background-color: #fff;
      cursor: pointer;
      padding-left: 20px;
      border-radius: 10px;
      @media screen and (min-width: $min-width-sm) {
        font-size: 18px;
        padding-left: 52px;
        border-radius: 15px;
      }
      
      &.is-placeholder {
        height: 40px;
        line-height: 40px;
        vertical-align: middle;
        position: absolute;
        top: 0;
        left: 0;
        color: #C2C2C2;
        background-color: transparent;
        @media screen and (min-width: $min-width-sm) {
          height: 84px;
          line-height: 84px;
        }
      }
    }
    
    &-expand:checked {
      + .select-close-label {
        display: block;
        
        + .select-options {
          background-color: #fff;
          border-radius: 10px;
          padding-bottom: 5px;
          @media screen and (min-width: $min-width-sm) {
            padding-bottom: 27px;
          }
          .select-label {
            height: 30px;
          }
          
          + .select-expand-label {display: none}
        }
      }
    }
    
    &-input:checked + .select-label {
      height: 40px;
      line-height: 40px;
      margin-top: -30px;
      @media screen and (min-width: $min-width-sm) {
        height: 40px;
        line-height: 40px;
        margin-top: -35px;
      }
    }
  }

  .fieldset {
    margin-top: 45px;
    @media screen and (min-width: $min-width-sm) {
      margin-top: 60px;
    }
    &.is-select {
      position: relative;
      z-index: 2;
    }
    .legend {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
      font-size: 14px;
      font-weight: $font-weight-bold;
      line-height: 20px;
      padding-inline-start: 0;
      padding-inline-end: 0;
      @media screen and (min-width: $min-width-sm) {
        margin-bottom: 60px;
        font-size: 18px;
        line-height: 28px;
      }
      .note {
        font-size: 12px;
        margin-left: 6px;
        @media screen and (min-width: $min-width-sm) {
          font-size: 18px;
          margin-left: 17px;
        }
      }
      &::before {
        display: block;
        content: "";
        flex: 1;
        width: 20px;
        height: 0;
        border-bottom: 1px solid #000;
        margin-right: 24px;
        @media screen and (min-width: $min-width-sm) {
          margin-right: 48px;
        }
      }
      &::after {
        display: block;
        content: "";
        flex: 1;
        width: 20px;
        height: 0;
        border-bottom: 1px solid #000;
        margin-left: 24px;
        @media screen and (min-width: $min-width-sm) {
          margin-left: 48px;
        }
      }
    }

    .text {
      margin-bottom: 22px;
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.025em;
      @media screen and (min-width: $min-width-sm) {
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 27px;
      }
    }
  }

  .error {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 45px;
    background-color: #00B3BF;
    padding-top: 15px;
    padding-bottom: 15px;
    @media screen and (min-width: $min-width-sm) {
      margin-top: 90px;
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .list {
      li {
        font-size: 12px;
        font-weight: $font-weight-bold;
        line-height: 30px;
        letter-spacing: 0.025em;
        color: #fff;
        @media screen and (min-width: $min-width-sm) {
          font-size: 18px;
          line-height: 40px;
        }
      }
    }
  }
}