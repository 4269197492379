
$font-en: 'Lato', sans-serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;

$min-width-xl: 1441px;
$max-width-xl: 1440px;

$min-width-l: 1280px;
$max-width-l: 1279px;

$min-width-md: 1024px;
$max-width-md: 1023px;

$min-width-sm: 769px;
$max-width-sm: 768px;

$min-width-xs: 481px;
$max-width-xs: 480px;

$z-header-logo: 1;
$z-header-nav: 2;
$z-header-button: 2;
$z-header: 4;
$z-leader-detail: 3;

$color-text: #000;
$color-bg: #F8F8F4;
$color-bg-tool: #F7FAF9;
$color-link: #00B3BF;
$color-error: #00B3BF;
$color-yellow: #EAD328;

