.p-training-impact-bottom {
  position: relative;
  margin-top: 35px;
  width: 100%;
  @media screen and (max-width: $max-width-sm) {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 395px;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (min-width: $min-width-sm) {
    max-width: 1500px;
    margin-top: 90px;
    margin-left: auto;
    margin-right: auto;
  }
  .prev {
    margin-bottom: 30px;
    font-size: 12px;
    font-weight: $font-weight-bold;
    line-height: 18px;
    letter-spacing: 0.025em;
    color: #666;
    @media screen and (max-width: $max-width-l) {
      display: table;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 60px;
      font-size: 18px;
      line-height: 27px;
    }
    @media screen and (min-width: $min-width-l) {
      position: absolute;
      top: 50%;
      left: 30px;
      transform: translateY(-50%);
      margin-bottom: 0;
    }
    a {
      display: flex;
      align-items: center;
      @media screen and (min-width: $min-width-sm) and (max-width: $max-width-l) {
        margin-right: 102px;
      }
      &::before {
        @media screen and (max-width: $max-width-sm) {
          position: absolute;
          top: 0;
          left: 20px;
        }
        content: "";
        width: 45px;
        height: 13px;
        margin-right: 28px;
        background-image: url(/assets/img/training/impact/prev-sp.svg);
        background-repeat: no-repeat;
        @media screen and (min-width: $min-width-sm) {
          position: relative;
          top: -4px;
          width: 74px;
          height: 21px;
          margin-right: 28px;
          background-image: url(/assets/img/training/impact/prev-pc.svg);
        }
      }
      transition: color 0.2s;
      &:hover {
        color: $color-link;
      }
    }
  }
  .next {
    margin-bottom: 30px;
    font-size: 12px;
    font-weight: $font-weight-bold;
    line-height: 18px;
    letter-spacing: 0.025em;
    color: #666;
    @media screen and (max-width: $max-width-l) {
      display: table;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 60px;
      font-size: 18px;
      line-height: 27px;
    }
    @media screen and (min-width: $min-width-l) {
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
      margin-bottom: 0;
    }
    a {
      display: flex;
      align-items: center;
      @media screen and (min-width: $min-width-sm) and (max-width: $max-width-l) {
        margin-left: 102px;
      }
      &::after {
        @media screen and (max-width: $max-width-sm) {
          position: absolute;
          top: 0;
          right: 20px;
        }
        content: "";
        width: 45px;
        height: 13px;
        margin-left: 28px;
        background-image: url(/assets/img/training/impact/next-sp.svg);
        background-repeat: no-repeat;
        @media screen and (min-width: $min-width-sm) {
          position: relative;
          top: -4px;
          width: 74px;
          height: 21px;
          margin-left: 28px;
          background-image: url(/assets/img/training/impact/next-pc.svg);
        }
      }
      transition: color 0.2s;
      &:hover {
        color: $color-link;
      }
    }
  }
}
  