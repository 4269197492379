.p-about-top {
  padding-top: 40px;
  margin-bottom: 40px;
  @media screen and (min-width: $min-width-sm) {
    padding-top: 90px;
    margin-bottom: 50px;
  }
  .wrapper {
    max-width: 375px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 40px;
    @media screen and (min-width: $min-width-sm) {
      max-width: 550px;
      margin-bottom: 80px;
    }

    .title {
      margin-bottom: 40px;
      font-size: 18px;
      font-weight: $font-weight-bold;
      line-height: 36px;
      letter-spacing: 0.025em;
      @media screen and (min-width: $min-width-sm) {
        margin-bottom: 50px;
        font-size: 34px;
        line-height: 60px;
      }
    }

    .text {
      font-size: 12px;
      line-height: 24px;
      @media screen and (min-width: $min-width-sm) {
        font-size: 18px;
        line-height: 36px;
      }
      span {
        white-space: nowrap;
      }
      + .text {
        margin-top: 24px;
        @media screen and (min-width: $min-width-sm) {
          margin-top: 35px;
        }
      }
    }
  }

  .figure {
    width: 100%;
    @media screen and (min-width: $min-width-sm) {
      max-width: 1160px;
      padding-left: 30px;
      padding-right: 30px;
      margin-left: auto;
      margin-right: auto;
    }
    img {
      width: 100%;
      height: auto;
    }
    .caption {
      margin-top: 10px;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      @media screen and (min-width: $min-width-sm) {
        margin-top: 23px;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
      }
    }
  }
}
  