.p-training-section {
  width: 100%;
  max-width: 458px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 45px;
  padding-left: 15px;
  padding-right: 15px;
  @media screen and (min-width: $min-width-sm) {
    max-width: 787px;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 90px;
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90px;
    background-color: rgba(#C2C2C2, 0.3);
    font-size: 14px;
    font-weight: $font-weight-bold;
    line-height: 20px;
    @media screen and (min-width: $min-width-sm) {
      height: 154px;
      font-size: 30px;
      line-height: 45px;
    }
  }

  .label {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 45px;
    margin-bottom: 32px;
    font-size: 14px;
    font-weight: $font-weight-bold;
    line-height: 20px;
    @media screen and (min-width: $min-width-sm) {
      margin-top: 90px;
      margin-bottom: 85px;
      font-size: 24px;
      line-height: 36px;
    }
    &::before {
      display: block;
      content: "";
      flex: 1;
      height: 0;
      border-bottom: 1px solid #000;
      margin-right: 24px;
      @media screen and (min-width: $min-width-sm) {
        margin-right: 71px;
      }
    }
    &::after {
      display: block;
      content: "";
      flex: 1;
      height: 0;
      border-bottom: 1px solid #000;
      margin-left: 24px;
      @media screen and (min-width: $min-width-sm) {
        margin-left: 71px;
      }
    }
  }

  .list {
    margin-bottom: 10px;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 90px;
    }
  }

  .etc {
    width: 226px;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.025em;
    @media screen and (min-width: $min-width-sm) {
      width: 442px;
      font-size: 21px;
      line-height: 33px;
    }
  }

  .text {
    margin-top: 45px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 12px;
    letter-spacing: 0.025em;
    line-height: 25px;
    @media screen and (min-width: $min-width-sm) {
      margin-top: 60px;
      font-size: 21px;
      line-height: 45px;
    }
    + .text {
      margin-top: 25px;
    }
  }
}
  