.p-contact {
  padding-top: 45px;
  padding-bottom: 45px;
  @media screen and (min-width: $min-width-sm) {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .title {
    font-size: 18px;
    line-height: 27px;
    font-weight: $font-weight-bold;
    letter-spacing: 0.025em;
    text-align: center;
    @media screen and (min-width: $min-width-sm) {
      font-size: 40px;
      line-height: 59px;
    }
  }

  .subtitle {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 518px;
    margin-top: 14px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 45px;
    padding-left: 45px;
    padding-right: 45px;
    font-size: 12px;
    line-height: 23px;
    color: #666666;
    text-align: center;
    @media screen and (min-width: $min-width-sm) {
      max-width: 635px;
      margin-top: 4px;
      margin-bottom: 90px;
      font-size: 14px;
    }
    &::before {
      display: block;
      content: "";
      flex: 1;
      height: 0;
      border-bottom: 1px solid #666;
      margin-right: 24px;
      @media screen and (min-width: $min-width-sm) {
        margin-right: 35px;
      }
    }
    &::after {
      display: block;
      content: "";
      flex: 1;
      height: 0;
      border-bottom: 1px solid #666;
      margin-left: 24px;
      @media screen and (min-width: $min-width-sm) {
        margin-left: 35px;
      }
    }
  }

  .wrapper {
    position: relative;
    width: 100%;
  }
  
  .inquiry,
  .request {
    width: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s;
    &:not(.is-show) {
      position: absolute;
      top: 0;
      left: 0;
    }
    &.is-show {
      opacity: 1;
      pointer-events: auto;
    }
  }

}