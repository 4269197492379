.p-tool-mail {
  background-color: $color-bg-tool;
  margin-bottom: 45px;
  padding-top: 53px;
  padding-bottom: 35px;
  @media screen and (min-width: $min-width-sm) {
    margin-bottom: 70px;
    padding-top: 60px;
    padding-bottom: 60px;
    max-width: 757px;
    margin-left: auto;
    margin-right: auto;
  }
  .title {
    display: table;
    margin-bottom: 34px;
    margin-left: auto;
    margin-right: auto;
    font-size: 32px;
    letter-spacing: 0.025em;
    line-height: 47px;
    font-weight: bold;
    small {
      font-size: 26px;
    }
    @media screen and (max-width: $max-width-sm) {
      text-align: right;
    }
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 45px;
    }
    img {
      width: 215px;
      @media screen and (max-width: $max-width-sm) {
        display: block;
        margin-bottom: 15px;
      }
      @media screen and (min-width: $min-width-sm) {
        vertical-align: -2px;
        margin-right: 16px;
      }
    }
  }
  .text {
    display: table;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    font-weight: $font-weight-medium;
    line-height: 36px;
    letter-spacing: 0.025em;
    @media screen and (min-width: $min-width-sm) {
      font-size: 21px;
      line-height: 42px;
    }
  }
}
  