.p-tool-trial {
  margin-bottom: 45px;
  @media screen and (min-width: $min-width-sm) {
    max-width: 757px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 120px;
  }
  
  .p-tool-title {
    margin-bottom: 25px;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 53px;
    }
  }

  .text {
    font-size: 12px;
    line-height: 25px;
    @media screen and (min-width: $min-width-sm) {
      font-size: 18px;
      line-height: 45px;
    }
     + .text {
      margin-top: 25px;
      @media screen and (min-width: $min-width-sm) {
        margin-top: 52px;
      }
     }
  }

  .c-button {
    margin-top: 41px;
    @media screen and (min-width: $min-width-sm) {
      margin-top: 52px;
    }
  }
}