html {
  width: 100%;
  min-height: 100vh;
}

body {
  width: 100%;
  min-height: 100vh;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: $font-weight-regular;
  color: $color-text;
  background-color: #fff;
  @media screen and (min-width: $min-width-md) {
    min-width: 1024px;
  }
  &.is-fixed {
    position: fixed;
  }
}

br {
  &.is-min-xs {
    display: none;
    @media screen and (min-width: $min-width-xs) {
      display: inline;
    }
  }
  &.is-min-sm {
    display: none;
    @media screen and (min-width: $min-width-sm) {
      display: inline;
    }
  }
  &.is-min-md {
    display: none;
    @media screen and (min-width: $min-width-md) {
      display: inline;
    }
  }
  &.is-min-l {
    display: none;
    @media screen and (min-width: $min-width-l) {
      display: inline;
    }
  }
  &.is-max-sm {
    display: none;
    @media screen and (max-width: $max-width-sm) {
      display: inline;
    }
  }
  &.is-max-l {
    display: none;
    @media screen and (max-width: $max-width-l) {
      display: inline;
    }
  }
}