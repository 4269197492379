.c-case {
  display: block;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  background-color: #F0F0F0;
  .index-img {
    width: 100%;
    height: 20.8vw;
    @media screen and (min-width: $min-width-sm) {
      height: 150px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .index-container {
    width: 100%;
    height: 95px;
    padding-top: 14px;
    padding-left: 14px;
    padding-right: 14px;
    @media screen and (min-width: $min-width-sm) {
      height: 181px;
      padding-top: 27px;
      padding-left: 29px;
      padding-right: 29px;
    }
    .index-title {
      width: 100%;
      height: 30px;
      margin-bottom: 11px;
      font-size: 12px;
      line-height: 15px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @media screen and (min-width: $min-width-sm) {
        height: 50px;
        margin-bottom: 47px;
        font-size: 20px;
        line-height: 25px;
      }
    }
    .index-organization {
      font-size: 10px;
      font-weight: $font-weight-light;
      line-height: 14px;
      @media screen and (min-width: $min-width-sm) {
        font-size: 14px;
        line-height: 25px;
      }
    }
  }
}
  