.l-header-button {
  position: relative;
  box-sizing: content-box;
  padding: 0;
  width: 25px;
  height: 26px;
  z-index: $z-header-button;
  margin-left: auto;
  @media screen and (min-width: $min-width-l) {
    display: none;
  }
  span {
    position: absolute;
    width: 25px;
    height: 3px;
    background-color: #707070;
    &:nth-child(1){
      top: 0;
      transition: width 0.2s, top 0.2s, transform 0.2s;
    }
    &:nth-child(2){
      top: 50%;
      transform: translateY(-1px);
      transition: opacity 0.2s;
    }
    &:nth-child(3){
      bottom: 0;
      transition: width 0.2s, top 0.2s, transform 0.2s;
    }
  }
  &.is-open {
    span {
      &:nth-child(1){
        width: 36px;
        top: 50%;
        left: -6px;
        transform-origin: center;
        transform: translateY(-50%) rotate(45deg);
      }
      &:nth-child(2){
        opacity: 0;
      }
      &:nth-child(3){
        width: 36px;
        bottom: 50%;
        left: -6px;
        transform-origin: center;
        transform: translateY(50%) rotate(-45deg);
      }
    }
  }
}