.p-training-impact-worry {
  width: 100%;
  padding: 60px 15px 45px;
  @media screen and (min-width: $min-width-sm) {
    padding: 90px 30px 90px;
  }

  .heading {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    font-size: 14px;
    font-weight: $font-weight-bold;
    line-height: 20px;
    @media screen and (min-width: $min-width-sm) {
      max-width: 818px;
      margin-bottom: 60px;
      font-size: 40px;
      line-height: 59px;
    }
    &::before {
      display: block;
      content: "";
      flex: 1;
      height: 0;
      border-bottom: 1px solid #000;
      margin-right: 24px;
      @media screen and (min-width: $min-width-sm) {
        margin-right: 30px;
      }
    }
    &::after {
      display: block;
      content: "";
      flex: 1;
      height: 0;
      border-bottom: 1px solid #000;
      margin-left: 24px;
      @media screen and (min-width: $min-width-sm) {
        margin-left: 30px;
      }
    }
  }

  .wrapper {
    @media screen and (min-width: $min-width-l) {
      display: flex;
      flex-direction:row-reverse;
      justify-content: space-between;
      width: 1098px;
      margin-left: auto;
      margin-right: auto;
    }
    .container {
      + .container {
        @media screen and (max-width: $max-width-l) {
          margin-top: 15px;
          @media screen and (min-width: $min-width-sm) {
            margin-top: 33px;
          }
      }
      }
      .worry {
        position: relative;
        width: 345px;
        margin-left: auto;
        margin-right: auto;
        @media screen and (min-width: $min-width-sm) {
          width: 518px;
        }
        + .worry {
          @media screen and (max-width: $max-width-l) {
            margin-top: 15px;
          }
          @media screen and (min-width: $min-width-sm) {
            margin-top: 33px;
          }
        }
    
        .block {
          position: relative;
          background-color: #F3F3F3;
          @media screen and (min-width: $min-width-sm) {
            position: absolute;
          }
          &.is-01,
          &.is-03 {
            width: 200px;
            padding-top: 15px;
            padding-left: 33px;
            margin-left: 40px;
            &.is-design {
              height: 102px;
            }
            &.is-managiment {
              height: 127px;
            }
            @media screen and (min-width: $min-width-sm) {
              left: 0;
              width: 258px;
              padding-top: 9px;
              margin-left: 0;
              &.is-design {
                top: 82px;
                height: 132px;
              }
              &.is-managiment {
                top: 80px;
                height: 170px;
              }
            }
            &::before {
              position: absolute;
              top: 38px;
              right: -22px;
              content: "";
              width: 22px;
              height: 14px;
              background-image: url(/assets/img/training/impact/worry-right.svg);
              background-size: 100% 100%;
              @media screen and (min-width: $min-width-sm) {
                top: 49px;
                right: -28px;
                width: 28px;
                height: 21px;
              }
            }
            .title {
              margin-bottom: 5px;
              @media screen and (min-width: $min-width-sm) {
                margin-bottom: 11px;
              }
            }
          }
          &.is-02,
          &.is-04 {
            width: 206px;
            padding-left: 40px;
            margin-left: 98px;
            &.is-design {
              padding-top: 20px;
              height: 140px;
            }
            @media screen and (min-width: $min-width-sm) {
              left: 251px;
              width: 266px;
              padding-top: 15px;
              margin-left: 0;
              &.is-design {
                top: 40px;
                height: 181px;
              }
            }
            &::before {
              position: absolute;
              top: 76px;
              left: -30px;
              content: "";
              width: 30px;
              height: 19px;
              background-image: url(/assets/img/training/impact/worry-left.svg);
              background-size: 100% 100%;
              @media screen and (min-width: $min-width-sm) {
                top: 77px;
                left: -40px;
                width: 40px;
                height: 14px;
              }
            }
            .title {
              margin-bottom: 11px;
              @media screen and (min-width: $min-width-sm) {
                margin-bottom: 19px;
              }
            }
          }
          &.is-02 {
            &.is-managiment {
              padding-top: 15px;
              height: 155px;
              @media screen and (min-width: $min-width-sm) {
                top: 40px;
                height: 216px;
              }
            }
          }
          &.is-04 {
            &.is-managiment {
              padding-top: 15px;
              height: 175px;
              @media screen and (min-width: $min-width-sm) {
                top: 19px;
                height: 235px;
                .title {
                  margin-bottom: 13px;
                }
              }
            }
          }


          .title {
            position: relative;
            font-size: 15px;
            line-height: 20px;
            @media screen and (min-width: $min-width-sm) {
              font-size: 24px;
              line-height: 36px;
            }
            strong {
              position: relative;
              display: inline-block;
              z-index: 2;
              &::before {
                position: absolute;
                bottom: 0;
                left: -2px;
                display: inline-block;
                content: "";
                width: calc(100% + 4px);
                height: 5px;
                background-color: $color-yellow;
                z-index: -1;
                @media screen and (min-width: $min-width-sm) {
                  left: -4px;
                  width: calc(100% + 8px);
                  height: 12px;
                }
              }
            }
            
          }
          .text {
            font-size: 12px;
            line-height: 25px;
            @media screen and (min-width: $min-width-sm) {
              font-size: 18px;
              line-height: 30px;
            }
          }
        }
      
        .img {
          @media screen and (max-width: $max-width-sm) {
            position: absolute;
            &.is-01 {
              left: 211px;
              width: 93px;
              &.is-design {
                top: -5px;
              }
              &.is-managiment {
                top: 11px;
              }
            }
            &.is-02 {
              top: 27px;
              left: 41px;
              width: 68px;
              &.is-design {
                top: 27px;
              }
              &.is-managiment {
                top: 46px;
              }
            }
            &.is-03 {
              top: -2px;
              left: 220px;
              width: 84px;
              &.is-design {
                top: -2px;
              }
              &.is-managiment {
                top: 12px;
              }
            }
            &.is-04 {
              top: 29px;
              left: 41px;
              width: 94px;
              &.is-design {
                top: 29px;
              }
              &.is-managiment {
                top: 47px;
              }
            }
          }
          @media screen and (min-width: $min-width-sm) {
            &.is-01 {
              margin-left: 311px;
              width: 205px;
            }
            &.is-02 {
              margin-left: 48px;
              width: 156px;
            }
            &.is-03 {
              margin-left: 292px;
              width: 188px;
            }
            &.is-04 {
              margin-left: 4px;
              width: 214px;
            }
          }
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    
  }

  

  
}
  