.l-header-nav {
  @media screen and (max-width: $max-width-l) {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    background-color: $color-bg;
    padding-top: 25px;
    padding-bottom: 35px;
    z-index: $z-header-nav;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s,visibility 0.2s;
    z-index: $z-header-nav;
    &.is-show {
      opacity: 1;
      visibility: visible;
    }
  }

  @media screen and (min-width: $min-width-l) {
    flex: 1;
    margin-left: 67px;
    height: 100%;
  }


  // .nav {
  //   li {
  //     + li {
  //       margin-top: 20px;
  //     }
  //     &.document {
  //       margin-left: auto;
  //       margin-right: auto;
  //     }
  //   }
  // }
  .nav {
    @media screen and (min-width: $min-width-l) {
      display: flex;
      align-items: center;
      height: 100%;
    }
    li {
      @media screen and (max-width: $max-width-l) {
        padding-left: 30px;
        padding-right: 30px;
      }
      &.top {
        @media screen and (min-width: $min-width-l) {
          display: none;
        }
      }
      a {
        position: relative;
        display: inline-block;
        font-size: 14px;
        line-height: 20px;
        text-decoration: none;
        color: #666;
        transition: opacity 0.2s;
        &.is-active {
          color: #000;
        }
        @include hover {
          color: #000;
        }
        @media screen and (min-width: $min-width-l) {
          font-size: 14px;
        }
      }

      &.document {
        width: 141px;
        @media screen and (min-width: $min-width-l) {
          margin-left: auto;
        }
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 141px;
          height: 30px;
          border: 1px solid #707070;
          @include hover {
            color: #fff;
            border: 1px solid #000;
            background-color: #000;
          }
        }
        
      }
      @media screen and (min-width: $min-width-l) {
        &:nth-child(n+3):not(.document) {
          margin-left: 60px;
        }
      }
      @media screen and (max-width: $max-width-l) {
        + li {
          margin-top: 20px;
        }
      }
    }
  }

}