.p-training-banner {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 45px;
  @media screen and (min-width: $min-width-xs) {
    display: flex;
    justify-content: center;
  }
  @media screen and (min-width: $min-width-sm) {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 90px;
  }
  .banner {
    width: 100%;
    max-width: 355px;
    @media screen and (max-width: $max-width-xs) {
      margin-left: auto;
      margin-right: auto;
      + .banner {
        margin-top: 15px;
      }
    }
    @media screen and (min-width: $min-width-xs) {
      max-width: 674px;
      + .banner {
        margin-left: 15px;
      }
    }
    @media screen and (min-width: $min-width-sm) {
      + .banner {
        margin-left: 32px;
      }
    }
    a {
      position: relative;
      display: block;
      @include hover {
        &:after {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          content:"";
          background-color: rgba(#000, 0.15);
          z-index: 2;
        }
      }
      img {
        image-rendering: -webkit-optimize-contrast;
        width: 100%;
        height: auto;
      }
    }
  }
}
  