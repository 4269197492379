.l-header {
  position: sticky;
  top: 0;
  display: flex;
  padding-left: 18px;
  padding-right: 15px;
  height: 77px;
  align-items: center;
  background-color: $color-bg;
  z-index: $z-header;
  &.is-tool {
    background-color: $color-bg-tool;
  }
  @media screen and (min-width: $min-width-l) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media screen and (min-width: $min-width-xl) {
  }
  .logo {
    position: relative;
    width: 135px;
    z-index: $z-header-logo;
    @media screen and (min-width: $min-width-sm) {
      width: 216px;
    }
    a {
      @include hover{
        opacity: 0.6;
      }
      img,
      svg {
        width: 100%;
        height: auto;
      }
    }
  }
}