.p-training-impact-program {
  width: 100%;
  padding: 60px 15px;
  @media screen and (min-width: $min-width-sm) {
    padding: 90px 30px;
  }

  .heading {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: $font-weight-bold;
    line-height: 20px;
    @media screen and (min-width: $min-width-sm) {
      max-width: 818px;
      margin-bottom: 60px;
      font-size: 40px;
      line-height: 59px;
    }
    &::before {
      display: block;
      content: "";
      flex: 1;
      height: 0;
      border-bottom: 1px solid #000;
      margin-right: 24px;
      @media screen and (min-width: $min-width-sm) {
        margin-right: 30px;
      }
    }
    &::after {
      display: block;
      content: "";
      flex: 1;
      height: 0;
      border-bottom: 1px solid #000;
      margin-left: 24px;
      @media screen and (min-width: $min-width-sm) {
        margin-left: 30px;
      }
    }
  }
  
  .text {
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
    line-height: 25px;
    color: #666;
    text-align: center;
    @media screen and (min-width: $min-width-sm) {
      max-width: 835px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 60px;
      font-size: 21px;
      line-height: 45px;
      letter-spacing: 0.025em;
    }
  }

  .program {
    display: table;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    @media screen and (min-width: $min-width-sm) {
      padding-left: 30px;
      &.is-design {
        width: 742px;
      }
      &.is-management {
        width: 852px;
      }
    }
    dt {
      float: left;
      clear: both;
      width: 90px;
      font-size: 12px;
      line-height: 19px;
      border-right: 1px solid #666;
      @media screen and (min-width: $min-width-sm) {
        width: 190px;
        font-size: 21px;
        font-weight: $font-weight-bold;
        line-height: 31px;
        letter-spacing: 0.025em;
      }
      &:nth-of-type(n+2) {
        margin-top: 30px;
      }
    }
    dd {
      float: left;
      max-width: calc(100% - 90px);
      padding-left: 10px;
      font-size: 12px;
      line-height: 19px;
      @media screen and (min-width: $min-width-sm) {
        max-width: calc(100% - 190px);
        padding-left: 56px;
        font-size: 21px;
        line-height: 31px;
      }
      &:nth-of-type(n+2) {
        margin-top: 30px;
      }
    }
  }

  .movie {
    position:relative;
    width: 100%;
    max-width: 428px;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: $min-width-sm) {
      max-width: 1129px;
      margin-top: 90px;
    }
  }
}
  