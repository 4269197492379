.p-index-mail {
  @media screen and (min-width: $min-width-sm) {
    max-width: 1340px;
    margin-left: auto;
    margin-right: auto;
    padding: 60px 30px 90px;
  }

  .container {
    background-color: #F0F0F0;
    padding-top: 53px;
    @media screen and (min-width: $min-width-sm) {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    .title {
      display: table;
      margin-bottom: 34px;
      margin-left: auto;
      margin-right: auto;
      font-size: 32px;
      letter-spacing: 0.025em;
      line-height: 47px;
      font-weight: bold;
      small {
        font-size: 26px;
      }
      @media screen and (max-width: $max-width-sm) {
        text-align: right;
      }
      @media screen and (min-width: $min-width-sm) {
        margin-bottom: 45px;
      }
      img {
        width: 215px;
        @media screen and (max-width: $max-width-sm) {
          display: block;
          margin-bottom: 15px;
        }
        @media screen and (min-width: $min-width-sm) {
          vertical-align: -2px;
          margin-right: 16px;
        }
      }
    }
    .text {
      display: table;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 35px;
      font-size: 18px;
      font-weight: $font-weight-medium;
      line-height: 36px;
      letter-spacing: 0.025em;
      @media screen and (min-width: $min-width-sm) {
        margin-bottom: 45px;
        font-size: 20px;
        line-height: 29px;
      }
    }
  
    .form {
      width: 100%;
      @media screen and (max-width: $max-width-sm) {
        padding: 30px 10px;
        background-color: #fff;
      }
      @media screen and (min-width: $min-width-sm) {
        display: flex;
        justify-content: center;
      }
      input[type="text"]  {
        display: block;
        width: 100%;
        max-width: 423px;
        height: 60px;
        font-size: 14px;
        font-weight: $font-weight-medium;
        line-height: 60px;
        text-align: center;
        @media screen and (max-width: $max-width-sm) {
          border: 1px solid #C2C2C2;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 18px;
        }
        @media screen and (min-width: $min-width-sm) {
          background-color: #fff;
          color: #666666;
          font-size: 18px;
        }
        &:focus {
          outline: none;
        }
      }
      .button {
        display: block;
        width: 100%;
        height: 60px;
        text-align: center;
        color: #fff;
        background-color: #666666;
        @include hover {
          background-color: #000;
        }
        @media screen and (max-width: $max-width-sm) {
          max-width: 355px;
          font-size: 14px;
          line-height: 60px;
          border: 1px solid #909090;
          border-radius: 35px;
          margin-left: auto;
          margin-right: auto;
        }
        @media screen and (min-width: $min-width-sm) {
          width: 111px;
        }
      }
    }
  }
  
}
  