.p-tool {
  width: 100%;
  max-width: 458px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
  @media screen and (min-width: $min-width-sm) {
    max-width: 100%;
    padding-top: 46px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .lead1 {
    margin-bottom: 14px;
    font-size: 14px;
    font-weight: $font-weight-bold;
    line-height: 20px;
    letter-spacing: 0.075em;
    text-align: center;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 29px;
      font-size: 30px;
      line-height: 45px;
    }
  }

  .lead2 {
    margin-bottom: 30px;
    font-size: 12px;
    font-weight: $font-weight-bold;
    line-height: 18px;
    letter-spacing: 0.075em;
    text-align: center;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 42px;
      font-size: 24px;
      line-height: 36px;
    }
  }

  .logo {
    margin-bottom: 20px;
    width: 336px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 51px;
      width: 727px;
      max-width: 100%;
    }
    img {
      image-rendering: -webkit-optimize-contrast;
      width: 100%;
      height: auto;
    }
  }

  .pc {
    width: 238px;
    margin-bottom: 45px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 95px;
      width: 784px;
      max-width: 100%;
    }
    img {
      image-rendering: -webkit-optimize-contrast;
      width: 100%;
      height: auto;
    }
  }
}