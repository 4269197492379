.p-case-detail {
  .wrapper {
    padding-bottom: 30px;
    @media screen and (min-width: $min-width-sm) {
      padding-bottom: 48px;
    }
    .img {
      width: 100%;
      @media screen and (min-width: 1441px) {
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 60px;
        padding-left: 30px;
        padding-right: 30px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .container {
      padding: 30px;
      width: 100%;
      @media screen and (min-width: $min-width-sm) {
        padding-top: 90px;
        padding-bottom: 57px;
        max-width: 876px;
        margin-left: auto;
        margin-right: auto;
      }
  
      .title {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: $font-weight-bold;
        line-height: 30px;
        letter-spacing: 0.025em;
        @media screen and (min-width: $min-width-sm) {
          margin-bottom: 25px;
          font-size: 30px;
          line-height: 50px;
        }
      }
  
      .author {
        margin-bottom: 30px;
        font-size: 12px;
        line-height: 25px;
        @media screen and (min-width: $min-width-sm) {
          margin-bottom: 50px;
          font-size: 18px;
          line-height: 36px;
        }
      }
  
      .post {
        font-size: 12px;
        line-height: 25px;
        color: #333;
        @media screen and (min-width: $min-width-sm) {
          font-size: 16px;
          line-height: 36px;
        }
        a {
          text-decoration: underline;
          @include hover {
            text-decoration: none;
          }
        }
        h2,h3,h4,h5,p,blockquote {
          &:not(:first-child) {
            margin-top: 30px;
            @media screen and (min-width: $min-width-sm) {
              margin-top: 40px;
            }
          }
          &:not(:last-child) {
            margin-bottom: 30px;
            @media screen and (min-width: $min-width-sm) {
              margin-bottom: 40px;
            }
          }
        }
        h2 {
          font-size: 16px;
          font-weight: bold;
          @media screen and (min-width: $min-width-sm) {
            font-size: 20px;
          }
        }
        h3 {
          font-weight: bold;
        }
        img {
          width: 100%;
          height: auto;
        }

        blockquote {
          background-color: #f5f8fa;
          padding: 18px 24px;
          font-size: 10px;
          font-weight: $font-weight-light;
          line-height: 24px;
          @media screen and (min-width: $min-width-sm) {
            padding: 25px 30px;
            font-size: 14px;
            line-height: 34px;
          }
          p {
            &:not(:first-child) {
              margin-top: 0;
              @media screen and (min-width: $min-width-sm) {
                margin-top: 0;
              }
            }
            &:not(:last-child) {
              margin-bottom: 0;
              @media screen and (min-width: $min-width-sm) {
                margin-bottom: 0;
              }
            }
          }
          
        }
      }
  
      .tag {
        display: flex;
        margin-top: 30px;
        @media screen and (min-width: $min-width-sm) {
          margin-top: 48px;
        }
        li {
          font-size: 10px;
          line-height: 25px;
          color: $color-link;
          @media screen and (min-width: $min-width-sm) {
            font-size: 21px;
            line-height: 31px;
          }
          a {
            color: inherit;
          }
          + li {
            margin-left: 10px;
            @media screen and (min-width: $min-width-sm) {
              margin-left: 21px;
            }
          }
        }
      }
  
      
    }
  
    .nav {
      position: relative;
      width: 100%;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 0.025em;
      color: #666666;
      @media screen and (min-width: $min-width-sm) {
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;
        line-height: 27px;
      }
      a {
        transition: opacity 0.2s;
        @include hover {
          opacity: 0.4;
        }
      }
      .prev {
        position: absolute;
        top: 0;
        left: 30px;
        a {
          display: flex;
          align-items: center;
          &::before {
            display: inline-block;
            content: "<";
            margin-right: 11px;
            font-size: 14px;
            font-weight: $font-weight-regular;
            line-height: 15px;
            vertical-align: middle;
            @media screen and (min-width: $min-width-sm) {
              position: relative;
              top: -4px;
              content: "";
              width: 74px;
              height: 21px;
              margin-right: 28px;
              background-image: url(/assets/img/case/prev.svg);
              background-repeat: no-repeat;
            }
          }
        }
      }

      .index {
        display: table;
        margin-left: auto;
        margin-right: auto;
      }

  
      .next {
        position: absolute;
        top: 0;
        right: 30px;
        a {
          display: flex;
          align-items: center;
          &::after {
            display: inline-block;
            content: ">";
            margin-left: 11px;
            font-size: 14px;
            font-weight: $font-weight-regular;
            line-height: 15px;
            @media screen and (min-width: $min-width-sm) {
              position: relative;
              top: -4px;
              content: "";
              width: 74px;
              height: 21px;
              margin-left: 28px;
              background-image: url(/assets/img/case/next.svg);
              background-repeat: no-repeat;
            }
          }
        }
      }
  
    }
  }
}