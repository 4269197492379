.l-footer {
  position: sticky;
  top: 100vh;
  background-color: $color-bg;
  &.is-tool {
    background-color: $color-bg-tool;
  }
  .nav {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 18px 7px;
    @media screen and (min-width: $min-width-sm) {
      padding: 45px 30px;
    }
    li {
      margin-top: 2px;
      margin-bottom: 2px;
      font-size: 10px;
      line-height: 20px;
      @media screen and (min-width: $min-width-sm) {
        font-size: 12px;
        line-height: 21px;
      }
      a {
        display: inline-block;
        padding-left: 1em;
        padding-right: 1em;
      }
      @media screen and (max-width: $max-width-sm) {
        &::before {
          content: '｜';
        }
        &:last-child::after {
          content: '｜';
        }
      }
      @media screen and (min-width: $min-width-sm) {
        &:not(:first-child)::before {
          content: '｜';
        }
      }

      @media screen and (max-width: 430px) {
        &:nth-child(4):after {
            content: '｜';
        }
      }
      @media screen and (min-width: 431px) and (max-width: 550px) {
        &:nth-child(5):after {
            content: '｜';
        }
      }
      @media screen and (min-width: 551px) and (max-width: 620px) {
        &:nth-child(6):after {
            content: '｜';
        }
      }
    }
  }

  .bottom {
    border-top: 1px solid #C2C2C2;
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    .sns {
      display: flex;
      align-items: center;
      height: 76px;
      li {
        + li {
          margin-left: 18px;
          @media screen and (min-width: $min-width-sm) {
            margin-left: 29px;
          }
        }
        &.facebook {
          width: 23px;
          @media screen and (min-width: $min-width-sm) {
            width: 33px;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
        &.twitter {
          width: 25px;
          @media screen and (min-width: $min-width-sm) {
            width: 35px;
          }
          img {
            margin-left: 6px;
            width: 75%;
            height: auto;
          }
        }
        &.note {
          width: 23px;
          @media screen and (min-width: $min-width-sm) {
            width: 33px;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .copyright {
      margin-left: 18px;
      font-size: 12px;
      line-height: 21px;
      color: #666666;
      @media screen and (min-width: $min-width-sm) {
        margin-left: 21px;
      }
    }
  }
}