.p-training-impact-voice {
  width: 100%;
  padding: 60px 15px;
  background-color: $color-bg;
  @media screen and (min-width: $min-width-sm) {
    padding: 90px 30px;
  }

  .heading {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 37px;
    font-size: 14px;
    font-weight: $font-weight-bold;
    line-height: 20px;
    @media screen and (min-width: $min-width-sm) {
      max-width: 818px;
      margin-bottom: 60px;
      font-size: 40px;
      line-height: 59px;
    }
    &::before {
      display: block;
      content: "";
      flex: 1;
      height: 0;
      border-bottom: 1px solid #000;
      margin-right: 24px;
      @media screen and (min-width: $min-width-sm) {
        margin-right: 30px;
      }
    }
    &::after {
      display: block;
      content: "";
      flex: 1;
      height: 0;
      border-bottom: 1px solid #000;
      margin-left: 24px;
      @media screen and (min-width: $min-width-sm) {
        margin-left: 30px;
      }
    }
  }

  .voice {
    width: 100%;
    max-width: 458px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    @media screen and (min-width: $min-width-sm) {
      max-width: 818px;
      padding-left: 0;
      padding-right: 0;
    }
    li {
      position: relative;
      font-size: 12px;
      line-height: 20px;
      padding-left: 21px;
      @media screen and (min-width: $min-width-sm) {
        padding-left: 23px;
        font-size: 18px;
        line-height: 30px;
      }
      &::before {
        position: absolute;
        top: 3px;
        left: 0;
        display: block;
        content: "";
        width: 14px;
        height: 14px;
        background-color: #666666;
        border-radius: 50%;
        @media screen and (min-width: $min-width-sm) {
          top: 7px;
          width: 18px;
          height: 18px;
        }
      }

      + li {
        margin-top: 20px;
        @media screen and (min-width: $min-width-sm) {
          margin-top: 28px;
        }
      }
    }
  }
  
  
}
  