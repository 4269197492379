.p-case-index {
  padding: 15px 10px;
  padding-bottom: 15px;
  @media screen and (min-width: $min-width-xs) {
    padding: 15px 15px;
  }
  @media screen and (min-width: $min-width-sm) {
    max-width: 814px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 30px 30px;
  }
  @media screen and (min-width: $min-width-md) {
    max-width: 1116px;
  }

  .title {
    margin-left: 18px;
    margin-bottom: 15px;
    font-size: 10px;
    line-height: 15px;
    @media screen and (min-width: $min-width-sm) {
      margin-left: 12px;
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .case {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 14px;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 35px;
    }
    li {
      width: calc(50% - 7px);
      @media screen and (max-width: $max-width-md) {
        &:nth-child(2n) {
          margin-left: 30px;
        }
      }
      @media screen and (max-width: $max-width-sm) {
        &:nth-child(2n) {
          margin-left: 14px;
        }
      }
      @media screen and (min-width: $min-width-sm) {
        width: calc(50% - 15px);
        max-width: 362px;
      }
      @media screen and (min-width: $min-width-md) {
        width: calc(33.33% - 20px);
        &:not(:nth-child(3n+1)) {
          margin-left: 30px;
        }
      }
      @media screen and (max-width: $max-width-md) {
        &:nth-child(n+3) {
          margin-top: 15px;
          @media screen and (min-width: $min-width-sm) {
            margin-top: 30px;
          }
        }
      }
      @media screen and (min-width: $min-width-md) {
        &:nth-child(n+4) {
          margin-top: 30px;
        }
      }
    }
  }
  .pagenation {
    display: flex;
    justify-content: center;
    align-items: baseline;
    li {
      a,span {
        padding-left: 3px;
        padding-right: 3px;
        @media screen and (min-width: $min-width-sm) {
          padding-left: 4px;
          padding-right: 4px;
        }
      }
      .prev,
      .next {
        display: inline-block;
        font-size: 14px;
        font-weight: $font-weight-regular;
        line-height: 1;
      }
      .number,
      .dot {
        display: inline-block;
        font-size: 10px;
        font-weight: $font-weight-regular;
        line-height: 1;
        @media screen and (min-width: $min-width-sm) {
          font-size: 14px;
        }
        &.is-active {
          font-size: 15px;
          @media screen and (min-width: $min-width-sm) {
            font-size: 21px;
          }
        }
      }
    }
    
  }
}
  