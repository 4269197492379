.p-training-impact-goal {
  width: 100%;
  padding: 12px 15px 45px;
  background-color: $color-bg;
  @media screen and (min-width: $min-width-sm) {
    padding: 52px 30px 57px;
    padding-right: 30px;
  }

  .container {
    @media screen and (min-width: $min-width-l) {
      display: flex;
      width: 1077px;
      margin-left: auto;
      margin-right: auto;
    }
    .img {
      width: 227px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
      @media screen and (min-width: $min-width-sm) {
        width: 449px;
        margin-bottom: 57px;
      }
      @media screen and (min-width: $min-width-l) {
        margin-bottom: 0;
        margin-left: 12px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  
    .block {
      @media screen and (min-width: $min-width-l) {
        margin-top: 33px;
      }
      .heading {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 428px;
        margin: 0 auto 50px;
        font-size: 14px;
        font-weight: $font-weight-bold;
        line-height: 20px;
        @media screen and (min-width: $min-width-sm) {
          max-width: 818px;
          margin-bottom: 61px;
          font-size: 40px;
          line-height: 59px;
        }
        @media screen and (min-width: $min-width-l) {
          width: 496px;
        }
        &::before {
          display: block;
          content: "";
          flex: 1;
          height: 0;
          border-bottom: 1px solid #000;
          margin-right: 24px;
          @media screen and (min-width: $min-width-sm) {
            margin-right: 30px;
          }
        }
        &::after {
          display: block;
          content: "";
          flex: 1;
          height: 0;
          border-bottom: 1px solid #000;
          margin-left: 24px;
          @media screen and (min-width: $min-width-sm) {
            margin-left: 30px;
          }
        }
      }
    
      .list {
        margin-bottom: 10px;
        @media screen and (min-width: $min-width-sm) {
          margin-bottom: 90px;
        }
      }
    }
  }
  
}
  