.p-index-case {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 15px;
  @media screen and (min-width: $min-width-xs) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media screen and (min-width: $min-width-sm) {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 75px;
  }
  .container {
    position: relative;
    @media screen and (min-width: $min-width-xl) {
      position: relative;
      width: 1176px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  

  .case {
    margin-bottom: 15px;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 84px;
    }
    .swiper-slide {
      width: calc(50% - 7.5px);
      @media screen and (max-width: $max-width-sm) {
        &:not(:first-child) {
          width: calc(50% + 7px);
          padding-left: 14px;
        }
      }
      @media screen and (min-width: $min-width-sm) {
        width: 392px;
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  .case-next {
    position: absolute;
    top: 50%;
    right: 80px;
    transform: translateY(-50%);
    z-index: 2;
    @media screen and (max-width: $max-width-sm) {
      display: none;
    }
    @media screen and (min-width: $min-width-xl) {
      right: -80px;
    }
  }

  .case-prev {
    position: absolute;
    top: 50%;
    left: 80px;
    transform: translateY(-50%);
    z-index: 2;
    @media screen and (max-width: $max-width-sm) {
      display: none;
    }
    @media screen and (min-width: $min-width-xl) {
      left: -80px;
    }
  }
  
}
  