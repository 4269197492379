.p-about {
  .bottom {
    margin-top: 10px;
    width: 100%;
    height: 208px;
    @media screen and (min-width: $min-width-sm) {
      height: 350px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}