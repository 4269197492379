.c-button {
  width: 335px;
  margin-left: auto;
  margin-right: auto;
  &.is-l {
    @media screen and (min-width: $min-width-sm) {
      width: 547px;
    }
  }
  a,
  button {
    display: block;
    width: 100%;
    height: 60px;
    font-size: 14px;
    font-weight: $font-weight-medium;
    line-height: 60px;
    text-align: center;
    color: #666666;
    border: 1px solid #909090;
    border-radius: 35px;
    transition: color 0.2s, background-color 0.2s;
    @include hover {
      color: #fff;
      background-color: #000;
    }
  }
}