.c-list {
  display: table;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  line-height: 17px;
  @media screen and (min-width: $min-width-sm) {
    margin-bottom: 90px;
    font-size: 21px;
    line-height: 33px;
  }
  li {
    + li {
      margin-top: 15px;
      @media screen and (min-width: $min-width-sm) {
        margin-top: 37px;
      }
    }
    &::before {
      display: inline-block;
      content: "";
      width: 14px;
      height: 14px;
      background-image: url(/assets/img/common/icon-check.svg);
      background-size: 100% auto;
      background-position: center center;
      background-repeat: no-repeat;
      margin-right: 6px;
      vertical-align: -2px;
      @media screen and (min-width: $min-width-sm) {
        width: 27px;
        height: 27px;
        margin-right: 28px;
        vertical-align: -6px;
      }
    }
  }
}