.c-contents {
  padding-top: 45px;
  padding-bottom: 45px;
  @media screen and (min-width: $min-width-sm) {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .title {
    font-size: 18px;
    line-height: 27px;
    font-weight: $font-weight-bold;
    letter-spacing: 0.025em;
    text-align: center;
    @media screen and (min-width: $min-width-sm) {
      font-size: 40px;
      line-height: 59px;
    }
  }

  .subtitle {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 518px;
    margin-top: 14px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 45px;
    padding-right: 45px;
    font-size: 12px;
    line-height: 23px;
    color: #666666;
    text-align: center;
    @media screen and (min-width: $min-width-sm) {
      max-width: 531px;
      margin-top: 4px;
      font-size: 18px;
      line-height: 27px;
    }
    &::before {
      display: block;
      content: "";
      flex: 1;
      height: 0;
      border-bottom: 1px solid #666;
      margin-right: 24px;
      @media screen and (min-width: $min-width-sm) {
        margin-right: 35px;
      }
    }
    &::after {
      display: block;
      content: "";
      flex: 1;
      height: 0;
      border-bottom: 1px solid #666;
      margin-left: 24px;
      @media screen and (min-width: $min-width-sm) {
        margin-left: 35px;
      }
    }
  }
  .contents {
    max-width: 518px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 45px;
    padding-left: 45px;
    padding-right: 45px;
    font-size: 12px;
    line-height: 23px;
    @media screen and (max-width: $max-width-sm) {
      letter-spacing: 0.025em;
    }
    @media screen and (min-width: $min-width-sm) {
      max-width: 1248px;
      margin-top: 90px;
      font-size: 14px;
      line-height: 24px;
    }
    
    h2 {
      font-size: 14px;
      font-weight: $font-weight-bold;
      @media screen and (min-width: $min-width-sm) {
        font-size: 18px;
      }

      + h3 {
        margin-top: 23px;
        @media screen and (min-width: $min-width-sm) {
          margin-top: 24px;
        }
      }
    }

    p {
      margin-bottom: 23px;
      @media screen and (min-width: $min-width-sm) {
        margin-bottom: 24px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: $color-link;
    }

    div {
      margin-bottom: 23px;
      @media screen and (min-width: $min-width-sm) {
        margin-bottom: 24px;
      }
      p,
      ul {
        margin-bottom: 0;
      }
    }

    ul {
      margin-bottom: 23px;
      @media screen and (min-width: $min-width-sm) {
        margin-bottom: 24px;
      }
      li {
        display: flex;
      }
    }
  }
  
}