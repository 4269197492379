.p-tool-reason {
  margin-bottom: 45px;
  @media screen and (min-width: $min-width-sm) {
    width: 100%;
    max-width: 1214px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 107px;
  }

  .p-tool-title {
    margin-bottom: 30px;
    @media screen and (min-width: $min-width-sm) {
      margin-bottom: 105px;
    }
  }

  .container {
    .box {
      width: 315px;
      background-color: #F2FBFC;
      padding: 7px 22px 24px;
      margin-bottom: -10px;
      @media screen and (min-width: $min-width-sm) {
        width: 529px;
        height: 447px;
        padding: 30px 0 0;
        margin-bottom: -50px;
      }
      @media screen and (min-width: $min-width-l) {
        margin-bottom: -410px;
      }
      .heading {
        margin-bottom: 11px;
        font-size: 14px;
        font-weight: $font-weight-bold;
        line-height: 44px;
        letter-spacing: 0.05em;
        @media screen and (min-width: $min-width-sm) {
          display: flex;
          align-items: flex-end;
          margin-bottom: 26px;
          margin-left: 40px;
          font-size: 21px;
          line-height: 42px;
        }
        span {
          font-size: 30px;
          font-weight: $font-weight-medium;
          color: #00B3BF;
          margin-right: 18px;
          @media screen and (min-width: $min-width-sm) {
            font-size: 100px;
            line-height: 1;
            margin-right: 30px;
          }
        }
      }

      .text {
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0.05em;
        @media screen and (min-width: $min-width-sm) {
          margin-bottom: 35px;
          margin-left: 128px;
          font-size: 21px;
          line-height: 42px;
        }
      }
      .caption {
        position: relative;
        width: 264px;
        margin-left: 128px;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0.05em;
        &::after {
          position: absolute;
          top: 0px;
          right: -21px;
          content: "▶︎";
          font-size: 21px;
          margin-right: 2px;
          color: #00B3BF;
        }
        @media screen and (max-width: $max-width-sm) {
          display: none;
        }
      }
      
    }

    .img {
      margin-left: auto;
      width: 315px;
      @media screen and (min-width: $min-width-sm) {
        width: 100%;
        max-width: 787px;
        padding-left: 30px;
        &:not(:last-of-type) {
          margin-bottom: 120px;
        }
      }
      img {
        width: 100%;
        height: auto;
      }

      .caption {
        margin-top: 4px;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.05em;
        text-indent: -12px;
        padding-left: 12px;
        &::before {
          content: "▶︎";
          font-size: 10px;
          margin-right: 2px;
          color: #00B3BF;
        }
        @media screen and (min-width: $min-width-sm) {
          display: none;
        }
      }
    }

    + .container {
      margin-top: 41px;
    }
  }
}