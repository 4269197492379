.p-index-top {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  @media screen and (min-width: $min-width-xs) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media screen and (min-width: $min-width-sm) {
    margin-top: 60px;
    margin-bottom: 60px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .wrapper {
    @media screen and (min-width: $min-width-md) {
      display: flex;
      max-width: 1380px;
      margin-left: auto;
      margin-right: auto;
      height: 426px;
    }
    .banner {
      position: relative;
      width: 100%;
      height: 400px;
      margin-bottom: 15px;
      @media screen and (min-width: $min-width-sm) {
        height: 426px;
        margin-bottom: 30px;
      }
      @media screen and (min-width: $min-width-md) {
        width: 66.46vw;
        max-width: 957px;
      }
      .swiper-slide {
        position: relative;
        width: 100%;
        width: 100%;
        .img {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        // .img {
        //   position: relative;
        //   width: 355px;
        //   margin-left: auto;
        //   margin-right: auto;
        //   @media screen and (min-width: $min-width-sm) {
        //     width: 104.02173913043478vw;
        //     max-width: 957px;
        //     &:not(.is-left) {
        //       position: absolute;
        //       top: 50%;
        //       left: 50%;
        //       transform: translate(-50%,-50%);
        //     }
        //   }
        //   @media screen and (min-width: $min-width-md) {
        //     width: 74.77vw;
        //     &.is-left {
        //       position: absolute;
        //       top: 50%;
        //       left: 0;
        //       transform: translateY(-50%);
        //     }
        //     &:not(.is-left) {
        //       position: absolute;
        //       top: 50%;
        //       left: 50%;
        //       transform: translate(-50%,-50%);
        //     }
        //   }
        //   @media screen and (min-width: $min-width-l) {
        //     width: 957px;
        //   }
        //   img {
        //     image-rendering: -webkit-optimize-contrast;
        //     width: 100%;
        //     height: auto;
        //   }
        // }
        // &.is-inpact {
        //   .bg {
        //     img {
        //       object-position: 50% 0%;
        //     }
        //   }
        // }   
      }
      
    }
  
    .block {
      @media screen and (min-width: $min-width-xs) and (max-width: $max-width-md) {
        display: flex;
      }
      @media screen and (min-width: $min-width-md) {
        flex: 1;
        height: 100%;
        margin-left: 30px;
      }
      .about {
        position: relative;
        width: 100%;
        height: 200px;
        background-image: url(/assets/img/index/about-bg.jpg);
        background-size: cover;
        background-position: center;
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          content: "";
          width: 100%;
          height: 100%;
          mix-blend-mode:multiply;
          background-color: rgba(#666666, 0.7);
          transition: background-color 0.2s;
        }
        @include hover {
          &::before {
            background-color: rgba(#666666, 1);
          }
        }
        @media screen and (max-width: $max-width-xs) {
          margin-bottom: 15px;
        }
        @media screen and (min-width: $min-width-md) {
          margin-bottom: 30px;
          height: 198px;
        }
        a {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          .container {
            .text {
              text-align: center;
              margin-bottom: 24px;
              span {
                display: inline-block;
                padding-left: 6px;
                padding-right: 6px;
                font-size: 19px;
                font-weight: $font-weight-black;
                line-height: 28px;
                color: #fff;
                text-align: center;
                @media screen and (min-width: $min-width-xs) {
                  font-size: 18px;
                }
                @media screen and (min-width: $min-width-sm) {
                  font-size: 21px;
                }
                @media screen and (min-width: $min-width-md) {
                  font-size: 18px;
                }
                @media screen and (min-width: $min-width-l) {
                  font-size: 21px;
                }
                &:first-child {
                  margin-bottom: 9px;
                }
              }
            }
            .link {
              display: block;
              width: 211px;
              height: 30px;
              margin-left: auto;
              margin-right: auto;
              font-size: 13px;
              font-weight: $font-weight-bold;
              line-height: 30px;
              color: #fff;
              text-align: center;
              background-color: #00B3BF;
              border-radius: 15px;
            }
          }
          
        }
      }
  
      .movie {
        position: relative;
        width: 100%;
        height: 200px;
        background-image: url(/assets/img/index/movie-bg.jpg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #EFF3F4;
        cursor: pointer;
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          content: "";
          width: 100%;
          height: 100%;
          mix-blend-mode:multiply;
          background-color: rgba(#666666, 0.7);
          transition: background-color 0.2s;
        }
        @media screen and (min-width: $min-width-xs) and (max-width: $max-width-sm) {
          margin-left: 15px;
        }
        @media screen and (min-width: $min-width-sm) and (max-width: $max-width-md) {
          margin-left: 30px;
        }
        @media screen and (min-width: $min-width-l) {
          height: 198px;
        }
        a {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          .container {
            .text {
              text-align: center;
              margin-bottom: 9px;
              span {
                display: inline-block;
                padding-left: 6px;
                padding-right: 6px;
                font-size: 19px;
                font-weight: $font-weight-black;
                line-height: 28px;
                color: #fff;
                text-align: center;
                background-color: #000;
                
                @media screen and (min-width: $min-width-xs) {
                  font-size: 18px;
                }
                @media screen and (min-width: $min-width-sm) {
                  font-size: 21px;
                }
                @media screen and (min-width: $min-width-md) {
                  font-size: 18px;
                }
                @media screen and (min-width: $min-width-l) {
                  font-size: 21px;
                }
                &:first-child {
                  margin-bottom: 9px;
                }
              }
            }
            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 48px;
              height: 48px;
              margin-left: auto;
              margin-right: auto;
              background-color: #000;
              border-radius: 50%;
              &::before {
                display: block;
                content: '';
                width: 0;
                height: 0;
                margin-left: 4px;
                border-left: 15px solid #fff;
                border-top: 9px solid transparent;
                border-bottom: 9px solid transparent;
              }
            }
          }
        }


        @include hover {
          &::before {
            background-color: rgba(#666666, 1);
          }
          a {
            .container {
              .icon {
                background-color: $color-link;
              }
            }
          }
        }
      }

    }
  }
}

.swiper-pagination {
  position: absolute;
  left: 26px;
  bottom: 17px;
  display: flex;
  width: 100%;
  z-index: 2;
  pointer-events: none;
  @media screen and (max-width: $max-width-sm) {
    left: 0;
    bottom: 18px;
    justify-content: center;
  }
  .swiper-pagination-bullet {
    display: block;
    width: 50px;
    height: 50px;
    background-color: #fff;
    border: 3px solid #C2C2C2;
    border-radius: 50%;
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    cursor: pointer;
    pointer-events: auto;
    &-active {
      background-color: #C2C2C2;
    }
    + .swiper-pagination-bullet {
      margin-left: 14px;
    }
  }
}